<img class="logo" src="./assets/logo.svg" />
<h1><a href="." target="_self">OSTŘÍŽ</a></h1>
<div class="login-wrap">
  <form (submit)="login()">
    <div class="form-row">
      <mat-form-field>
        <input matInput placeholder="Přihlašovací jméno" autocomplete="username" [(ngModel)]="username" name="username" />
      </mat-form-field>
    </div>
    <div class="form-row">
      <mat-form-field class="password">
        <input matInput placeholder="Heslo" [type]="hidePassword ? 'password' : 'text'"  autocomplete="current-password" [(ngModel)]="password" name="password" />
        <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-pressed]="hidePassword" type="button">
          <mat-icon>{{hidePassword ? 'visibility_off' : 'visibility'}}</mat-icon>
        </button>
      </mat-form-field>
    </div>
    <div class="form-row">
      <button mat-flat-button color="accent" type="submit">Přihlásit se</button>
    </div>
  </form>
</div>
<p class="footer">verze {{ version }} | &copy; Audiopro s.r.o.</p>
