import {Component, ElementRef, OnInit} from '@angular/core';
import {AuthService, MainService} from '@app/02-core/services';
import {Router} from '@angular/router';
import {environment} from '@env/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public version = environment.version;
  public hidePassword = true;
  public username: string = null;
  public password: string = null;

  constructor(
    private mainService: MainService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  async login(): Promise<void> {
    try {
      await this.authService.login(this.username, this.password);
      await this.router.navigate(['/']);
    } catch (e) {
      this.mainService.showError('Špatné přihlašovací údaje');
    }
  }

}
