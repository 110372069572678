import { NgModule } from '@angular/core';
import { CoreModule } from '@app/02-core/core.module';
import { LoginComponent } from '@app/03-modules/login-layout/login/login.component';
import { LoginRouter } from '@app/03-modules/login-layout/login.routes';
import { LoginGuard } from '@app/02-core/guards';

@NgModule({
  declarations: [
    LoginComponent
  ],
  imports: [
    CoreModule,
    LoginRouter
  ],
  exports: [
  ],
  providers: [
    LoginGuard
  ]
})
export class LoginLayoutModule {
}
