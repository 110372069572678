import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {Platform} from '@angular/cdk/platform';
import {TitleService} from '@app/02-core/services/title.service';
import {SnackbarService} from '@app/02-core/services/snackbar.service';
import {NavigationExtras, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class MainService {

  private sidebarShow = false;

  private $sidebarShow: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public sidebarShow$: Observable<boolean> = this.$sidebarShow.asObservable();
  private $loading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: Observable<boolean> = this.$loading.asObservable();

  constructor(
    private platform: Platform,
    private titleService: TitleService,
    private snackbarService: SnackbarService,
    private router: Router
  ) {

  }

  public toggleSidebar(): void {
    this.sidebarShow = !this.sidebarShow;
    this.$sidebarShow.next(this.sidebarShow);
  }

  public showLoading(): void {
    setTimeout(() => {
      this.$loading.next(true);
    }, 0);
  }

  public hideLoading(): void {
    setTimeout(() => {
      this.$loading.next(false);
    }, 0);
  }

  public isIOS(): boolean {
    return this.platform.IOS || this.platform.SAFARI;
  }

  public async navigate(commands: any[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  public setTitle(title: string): void {
    this.titleService.setTitle(title);
  }

  public showSuccess(message: string): void {
    this.snackbarService.showSuccess(message);
  }

  public showError(message: string): void {
    this.snackbarService.showError(message);
  }
}
