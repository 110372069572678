import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dateDiffSeconds' })
export class DatesDiffSecondsPipe implements PipeTransform {
  constructor(
  ) {}
  transform(newDate: Date, oldDate: Date): number {
    return newDate && oldDate && newDate > oldDate ? ((new Date(newDate)).getTime() - (new Date(oldDate)).getTime()) / 1000 : 0;
  }
}
