import { NgModule } from '@angular/core';
import { CoreModule } from '@app/02-core/core.module';
import { DialogService } from '@app/03-modules/dialogs/dialog.service';
import {ReactiveFormsModule} from '@angular/forms';
import {BookmarkDialogComponent} from '@app/03-modules/dialogs/bookmark-dialog.component';
import {ConfirmDialogComponent} from '@app/03-modules/dialogs/confirm-dialog.component';
import {PasswordDialogComponent} from '@app/03-modules/dialogs/password-dialog.component';
import {ExportDialogComponent} from '@app/03-modules/dialogs/export-dialog.component';
import {AlertDialogComponent} from '@app/03-modules/dialogs/alert-dialog.component';


@NgModule({
  declarations: [
    BookmarkDialogComponent,
    ConfirmDialogComponent,
    PasswordDialogComponent,
    ExportDialogComponent,
    AlertDialogComponent
  ],
  imports: [
    CoreModule,
    ReactiveFormsModule
  ],
  providers: [DialogService]
})
export class DialogModule {
}
