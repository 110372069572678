import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from '@app/01-layout/layout/layout.component';
import {AdminGuard, MainLoginGuard} from '@app/02-core/guards';

const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [MainLoginGuard],
    children: [
      {
        path: 'events',
        loadChildren: () => import('./03-modules/events/events.module').then(m => m.EventsModule)
      },
      {
        path: 'sources',
        loadChildren: () => import('./03-modules/sources/sources.module').then(m => m.SourcesModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'groups',
        loadChildren: () => import('./03-modules/groups/groups.module').then(m => m.GroupsModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'user-groups',
        loadChildren: () => import('./03-modules/user-groups/user-groups.module').then(m => m.UserGroupsModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'users',
        loadChildren: () => import('./03-modules/users/users.module').then(m => m.UsersModule),
        canActivate: [AdminGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./03-modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AdminGuard]
      },
      {
        path: '',
        redirectTo: 'events',
        pathMatch: 'full'
      },
      {
        path: '**',
        redirectTo: 'events',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: '',
    redirectTo: 'events',
    pathMatch: 'full'
  },
  {
    path: '**',
    redirectTo: 'events',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
