import {AfterViewInit, Directive, EventEmitter, Output } from '@angular/core';

@Directive({
  selector: '[appAfterViewInit]',
})
export class AfterViewInitDirective implements AfterViewInit {

  @Output() afterViewInit = new EventEmitter<void>();

  constructor() {}

  ngAfterViewInit(): void {
    this.afterViewInit.emit();
  }
}
