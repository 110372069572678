import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({providedIn: 'root'})
@Injectable()
export class SnackbarService {

  constructor(
    private snackBar: MatSnackBar
  ) {

  }

  public showError(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = ['error'];
    this.snackBar.open(message, null, config);
  }

  public showSuccess(message: string): void {
    const config = new MatSnackBarConfig();
    config.duration = 3000;
    config.panelClass = ['success'];
    this.snackBar.open(message, null, config);
  }
}
