import { NgModule } from '@angular/core';
import { LayoutComponent } from './layout/layout.component';
import { CoreModule } from '@app/02-core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@app/03-modules/dialogs/dialog.module';
import {SidebarComponent} from '@app/01-layout/sidebar/sidebar.component';
import {HeaderComponent} from '@app/01-layout/header/header.component';

@NgModule({
  declarations: [
    LayoutComponent,
    SidebarComponent,
    HeaderComponent
  ],
  imports: [
    CoreModule,
    ReactiveFormsModule,
    DialogModule
  ],
  providers: [
  ]
})
export class LayoutModule {
}
