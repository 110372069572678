import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'chipName' })
export class ChipNamePipe implements PipeTransform {
  constructor(
  ) {}
  transform(id: string, objects: any[], properties?: string[], joiner?: string): string {
    const object = objects.find(o => o._id === id);
    if (object) {
      if (properties) {
        const values = properties.map(p => object[p]);
        return values.join(joiner || ' ');
      } else {
        return object.name;
      }
    }
    return id;
  }
}
