import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'optionLdapName' })
export class OptionLdapNamePipe implements PipeTransform {
  constructor(
  ) {}
  transform(object: any, localObjects: any[], fqdn: string): string {
    if (localObjects.findIndex(g => g._id === object._id) < 0) {
      return `${object.name} (${fqdn})`;
    }
    return object.name;
  }
}
