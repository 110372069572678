import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-dialog',
  template: `
    <h4 mat-dialog-title>Exportovat výslech</h4>
    <mat-dialog-content>
      <p>Vyberte součásti exportu a cílový USB klíč.</p>
      <ng-container *ngFor="let option of options">
        <div>
          <mat-checkbox [(ngModel)]="option.selected">{{ option.name }} <b *ngIf="option.size">({{ option.size | bytesConvert }})</b></mat-checkbox>
        </div>
      </ng-container>
      <br />
      <p>CELKEM cca <b>{{ selectedOptionsSize() | bytesConvert}}</b>.</p>
      <div class="form-row">
        <mat-form-field>
          <mat-select placeholder="Exportovat na" [(ngModel)]="exportTo">
            <mat-option *ngFor="let target of targets" [value]="target.name">{{ target.label }} ({{ target.size }})</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-button color="warn" (click)="dialogRef.close()">Zrušit</button>
      <button type="button" mat-raised-button color="accent" (click)="selectOptions()">OK</button>
    </mat-dialog-actions>
  `,
})
export class ExportDialogComponent {

  public options: any[];
  public targets: any[];
  public exportTo: string;

  constructor(
    public dialogRef: MatDialogRef<ExportDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.options = data.options;
    this.targets = data.targets;
    this.exportTo = this.targets[0].name;
  }

  selectedOptionsSize(): number {
    return this.options.filter(o => o.selected).reduce((acc, o) => { acc += o.size; return acc; }, 0);
  }

  selectOptions(): void {
    this.dialogRef.close({export: this.options.filter(o => o.selected).map(o => o._id).join(','), exportTo: this.exportTo});
  }
}
