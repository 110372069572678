import {Injectable, isDevMode} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {CrudService} from '@app/02-core/services/crud.service';
import {SettingsInterface} from '@app/03-modules/settings/interfaces/settings.interface';

@Injectable({providedIn: 'root'})
export class SettingsService extends CrudService {

  // Subjects
  public $settings: BehaviorSubject<SettingsInterface> = new BehaviorSubject(null);

  // Observables of Subjects
  public settings$: Observable<SettingsInterface> = this.$settings.asObservable();

  constructor(
  ) {
    super('settings');
  }

  public async load(): Promise<void> {
    const settings = await this.find();
    this.$settings.next(settings);
  }

  public async save(data): Promise<void> {
    const settings = await this.patch(null, data);
    this.$settings.next(settings);
  }
}
