<video #video preload="auto" playsinline [src]="src"></video>
<div class="video-controls" [ngClass]="{visible: controlsVisible || isPaused  || isSeaking}" (mouseenter)="showControls()" (mouseleave)="hideControls()" *ngIf="isMetaLoaded && !this.controls.disabled">
  <div class="video-controls-top" *ngIf="controls.seek || controls.live">
    <div class="video-progress" *ngIf="controls.seek">
      <mat-progress-bar
        color="primary"
        mode="buffer"
        [value]="progress"
        [bufferValue]="buffer"></mat-progress-bar>
      <mat-slider class="seek" min="0" max="100" step="1" color="default" (input)="changeSeek($event)"></mat-slider>
    </div>
    <button *ngIf="hasLive && controls.live" class="live-toggle" mat-button color="warn">ŽIVĚ</button>
  </div>
  <div class="video-controls-bottom" *ngIf="controls.play || controls.time || controls.mute || controls.volume || controls.fullscreen">
    <button mat-mini-fab color="default" (click)="togglePlay()" *ngIf="controls.play">
      <mat-icon *ngIf="isPaused">play_arrow</mat-icon>
      <mat-icon *ngIf="!isPaused">pause</mat-icon>
    </button>
    <div class="time" *ngIf="controls.time">{{ elapsedTime }} / {{ durationTime }}</div>
    <button class="mute" mat-mini-fab color="default" (click)="toggleMute()" *ngIf="controls.mute">
      <mat-icon *ngIf="isMuted">volume_off</mat-icon>
      <mat-icon *ngIf="!isMuted">volume_up</mat-icon>
    </button>
    <mat-slider class="volume" min="0" max="100" [(ngModel)]="volume" step="1" color="primary" (input)="changeVolume($event)" *ngIf="controls.volume"></mat-slider>
    <button mat-mini-fab color="default" (click)="toggleFullscreen()" *ngIf="controls.fullscreen"><mat-icon>fullscreen</mat-icon></button>
  </div>
</div>
