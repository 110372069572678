<div class="header" fxFlex=" 1 1 auto" fxLayout="row" fxLayoutAlign="start center">
  <h1><a href="." target="_self">OSTŘÍŽ</a></h1>
  <h2 *ngIf="router.isActive('/events', false)" >Výslechy</h2>
  <h2 *ngIf="router.isActive('/groups', false)" >Místnosti</h2>
  <h2 *ngIf="router.isActive('/sources', false)" >Zdroje</h2>
  <h2 *ngIf="router.isActive('/users', false)" >Uživatelé</h2>
  <h2 *ngIf="router.isActive('/settings', false)" >Nastavení platformy</h2>
  <div fxFlex="1 1 auto" fxLayout="row" fxLayoutAlign="end center">
    <button mat-icon-button *ngIf="(authService.user$ | async).isAdmin || (authService.user$ | async).isOwner" [mat-menu-trigger-for]="addMenu"><mat-icon>add</mat-icon></button>
    <button mat-icon-button [mat-menu-trigger-for]="userMenu"><mat-icon>more_horiz</mat-icon></button>
  </div>
</div>
<mat-menu #userMenu="matMenu">
  <div mat-menu-item class="title">
    <mat-icon>person</mat-icon>
    <span>{{ (authService.user$ | async).name }}</span>
  </div>
  <a mat-menu-item [routerLink]="['/events']" *ngIf="(authService.user$ | async).isAdmin || (authService.user$ | async).isOwner">
    <mat-icon color="primary">hearing</mat-icon>
    <span>Výslechy</span>
  </a>
  <a mat-menu-item [routerLink]="['/groups']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon color="primary">meeting_room</mat-icon>
    <span>Místnosti</span>
  </a>
  <a mat-menu-item [routerLink]="['/sources']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon color="primary">videocam</mat-icon>
    <span>Zdroje</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="['/users']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon color="accent">people</mat-icon>
    <span>Uživatelé</span>
  </a>
  <mat-divider *ngIf="(authService.user$ | async).isAdmin"></mat-divider>
<!--  <mat-divider></mat-divider>-->
<!--  <button mat-menu-item>-->
<!--    <mat-icon>help</mat-icon>-->
<!--    <span>Podpora</span>-->
<!--  </button>-->
<!--  <button mat-menu-item>-->
<!--    <mat-icon>info</mat-icon>-->
<!--    <span>O aplikaci</span>-->
<!--  </button>-->
  <button mat-menu-item (click)="changePassword()" *ngIf="!(authService.user$ | async).isLdap">
    <mat-icon color="accent">lock</mat-icon>
    <span>Změna hesla</span>
  </button>
  <button mat-menu-item (click)="logout()">
    <mat-icon color="warn">exit_to_app</mat-icon>
    <span>Odhlásit se</span>
  </button>
</mat-menu>
<mat-menu #addMenu="matMenu">
  <a mat-menu-item [routerLink]="['/events/add']" *ngIf="(authService.user$ | async).isAdmin || (authService.user$ | async).isOwner">
    <mat-icon color="primary">hearing</mat-icon>
    <span>Nový výslech</span>
  </a>
  <a mat-menu-item [routerLink]="['/groups/add']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon color="primary">meeting_room</mat-icon>
    <span>Nová místnost</span>
  </a>
  <a mat-menu-item [routerLink]="['/sources/add']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon color="primary">videocam</mat-icon>
    <span>Nový zdroj</span>
  </a>
  <mat-divider></mat-divider>
  <a mat-menu-item [routerLink]="['/users/add']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon color="accent">people</mat-icon>
    <span>Nový uživatel</span>
  </a>
  <a mat-menu-item [routerLink]="['/user-groups/add']" *ngIf="(authService.user$ | async).isAdmin">
    <mat-icon color="accent">group_work</mat-icon>
    <span>Nová skupina</span>
  </a>
</mat-menu>
