import { Pipe, PipeTransform } from '@angular/core';
import {DataService} from '@app/02-core/services';

@Pipe({ name: 'chipLdapName' })
export class ChipLdapNamePipe implements PipeTransform {
  constructor(
  ) {}
  async transform(objectId: string, localObjects: any[], fqdn: string, ldapService: DataService<any>): Promise<string> {
    return new Promise(async (resolve) => {
      if (objectId && localObjects) {
        const localObject = localObjects.find(o => o._id === objectId);
        if (localObject) {
          resolve(localObject.name);
        } else {
          try {
            const ldapObject = await ldapService.get(objectId);
            resolve(`${ldapObject ? ldapObject.name : objectId} (${fqdn})`);
          } catch (e) {

          }
        }
      } else {
        resolve('');
      }
    });
  }
}
