import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthService, MainService} from '@app/02-core/services';
import {ActivatedRoute, Router, NavigationEnd} from '@angular/router';
import {DialogService} from '@app/03-modules/dialogs/dialog.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public activeRoute: string;

  constructor(
    public mainService: MainService,
    private route: ActivatedRoute,
    public router: Router,
    public authService: AuthService,
    private dialogsService: DialogService
  ) {

  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  async logout(): Promise<void> {
    await this.authService.logout();
    await this.router.navigate(['/login']);
  }

  async changePassword(): Promise<void> {
    const password = await this.dialogsService.password();
  }

}
