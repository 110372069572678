import {ApiService} from '@app/02-core/services/api.service';
import {Inject} from '@angular/core';
import {CoreModule} from '@app/02-core/core.module';

export class CrudService {
  protected service: any;
  protected apiService;

  constructor(
    @Inject('servicePath') protected servicePath: string
  ) {
    this.apiService = CoreModule.injector.get(ApiService);
    this.service = this.apiService.service(servicePath);
  }

  public find(params: any = {}): any {
    return this.service.find(params);
  }

  public get(id: string, params: any = {}): any {
    return this.service.get(id, params);
  }

  public create(item: any, params: any = {}): any {
    return this.service.create(item, params);
  }

  public patch(id: string, item: any, params: any = {}): any {
    return this.service.patch(id, item, params);
  }

  public remove(id: string, params: any = {}): any {
    return this.service.remove(id, params);
  }
}
