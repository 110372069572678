import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {CrudService} from '@app/02-core/services/crud.service';
import {Inject} from '@angular/core';

export interface DataServiceItem {
  _id?: string;
}

export class DataService<T extends DataServiceItem> extends CrudService {

  protected pagination: number = null;
  protected from = 0;
  protected query: any = {};
  protected items: T[] = [];
  public $items: BehaviorSubject<T[]> = new BehaviorSubject([]);
  public items$: Observable<T[]> = this.$items.asObservable();
  protected $created: Subject<T> = new Subject();
  public created$: Observable<T> = this.$created.asObservable();
  protected $updated: Subject<T> = new Subject();
  public updated$: Observable<T> = this.$updated.asObservable();
  protected $removed: Subject<T> = new Subject();
  public removed$: Observable<T> = this.$removed.asObservable();
  protected $isLoading: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public isLoading$: Observable<boolean> = this.$isLoading.asObservable();
  public total: number;

  constructor(
    @Inject('servicePath') protected servicePath: string
  ) {
    super(servicePath);
    this.service.on('created', (item: T) => this.created(item));
    this.service.on('updated', (item: T) => this.updated(item));
    this.service.on('patched', (item: T) => this.updated(item));
    this.service.on('removed', (item: T) => this.removed(item));
  }

  protected created(item: T): void {
    if (item) {
      const index = this.items.findIndex((i) => i._id === item._id);
      if (index < 0) {
        this.items.unshift(item);
        this.total++;
        this.setItems();
      }
      this.$created.next(item);
    }
  }

  protected updated(item: T): void {
    if (item) {
      const index = this.items.findIndex((i) => i._id === item._id);
      if (index >= 0) {
        this.items[index] = item;
        this.setItems();
      }
      this.$updated.next(item);
    }
  }

  protected removed(item: T): void {
    if (item) {
      const index = this.items.findIndex((i) => i._id === item._id);
      if (index >= 0) {
        this.items.splice(index, 1);
        this.total--;
        this.setItems();
      }
      this.$removed.next(item);
    }
  }

  protected setItems(items?: T[]): void {
    if (items) {
      this.items = items;
    }
    this.$items.next(this.items);
  }

  public async load(from?: number): Promise<void> {
    this.$isLoading.next(true);
    let data = [];
    try {
      if (this.pagination) {
        if (Number.isInteger(from)) {
          this.from = from;
          this.items = [];
        }
        this.query.$limit = this.pagination;
        this.query.$skip = this.from;
        const result = await this.find({query: this.query});
        this.total = result.total;
        this.from += this.pagination;
        data = result.data;
        this.items.push(...data);
        this.setItems();
      } else {
        data = await this.find({query: this.query});
        this.setItems(data);
      }
    } finally {
      this.$isLoading.next(false);
    }
  }
}
