import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'secondsToTime' })
export class SecondsToTimePipe implements PipeTransform {
  constructor(
  ) {}
  transform(seconds: number): string {
    if (!seconds || seconds < 0) {
      seconds = 0;
    }
    const time = new Date(Date.UTC(0, 0, 0, -1, 0, seconds));
    return Intl.DateTimeFormat('cs-CS', {hour: '2-digit', minute: '2-digit', second: '2-digit'}).format(time);
  }
}
