import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '@app/03-modules/login-layout/login/login.component';
import { LoginGuard } from '@app/02-core/guards';

export const loginRoutes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard]
  }
];

export const LoginRouter = RouterModule.forChild(loginRoutes);
