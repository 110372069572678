import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import {AuthService} from '@app/02-core/services';

@Injectable()
export class AdminGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthService,
  ) {

  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return new Promise(async (resolve) => {
      const user = this.authService.$user.getValue();
      if (user.isAdmin) {
        resolve(true);
      } else {
        resolve(false);
        await this.router.navigate(['/events']);
      }
    });
  }
}
