import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-dialog',
  template: `
    <h4 mat-dialog-title>{{ title }}</h4>
    <mat-dialog-content [innerHTML]="message"></mat-dialog-content>
    <mat-dialog-actions>
      <button type="button" mat-raised-button color="accent" (click)="dialogRef.close(true)">OK</button>
    </mat-dialog-actions>
  `,
})
export class AlertDialogComponent {

  public title: string;
  public message: string;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any
  ) {
    this.title = data.title;
    this.message = data.message;
  }
}
